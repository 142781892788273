<template lang="pug">
.partCategories
    v-row
        v-col
            span.text-italic.black--text Warning: When adding part categories, the category will be imported into the Sales Tool database permanently, and cannot be removed. 
    v-row
        v-col(cols='12')
            v-card.mb-12(light, flat)
                v-card-text
                v-simple-table.striped.hoverable(dense)
                    template(v-slot:default)
                        thead
                            tr
                                th Category
                        tbody
                            tr(v-for='category in categories', :key='category.id', @click='editType(laborType)')
                                td
                                    span {{ category.name }}

                template
                    v-row
                        v-col.ml-4
                            v-btn.white--text(color='green', @click='showNewTypeModal=true') Add Category
                v-overlay(absolute, :value='busy')
                    .text-h6 Please Wait...
                    v-progress-linear(indeterminate)

    v-row
        v-col
            v-btn.mr-2(@click='importPartData') Run Parts Import
            span.text-italic.black--text(align='left') Jonas data is updated nightly. Running the import will only import data as recent as midnight.

    v-dialog(v-model='showNewTypeModal', max-width='50vw')
        v-card
            v-card-title Add New Category
            v-card-text
                v-form(ref='form')
                    v-row
                        v-col
                            v-text-field.white--text(v-model='categoryTemplate.name', placeholder='Category', outlined)
            v-card-actions
                v-spacer
                v-btn.white--text(@click='addNewCategory', color='green') Add Category

    v-snackbar(v-model='showAlert', dark, :timeout='3000') {{ message }}
</template>

<script>
import _ from 'lodash';

export default {
    data () {
        return {
            dti,
            accessKey: 'satbonds',
            message: '',
            busy: false,

            categories: [],
            categoryTemplate: {
                name: '',
                enabled: true
            },
            showNewTypeModal: false
        };
    },
    methods: {
        async addNewCategory () {
            const cats = _.cloneDeep(this.categories);
            const newCat = _.cloneDeep(this.categoryTemplate);
            newCat.id = dti.makeuuid();
            cats.push(newCat);
            await this.updateCategories(cats);
            this.showNewTypeModal = false;
            this.$refs.form.reset();
        },
        async updateCategories (categories) {
            let result = await this.sendCommand({
                action: 'updatePartCategories',
                parameters: categories
            });
            if (result) {
                this.message = `Part categories updated successfully`;
                this.getData();
            } else {
                console.log('error updating part categories');
                this.message = `Error updating part categories`;
            }
        },
        getData () {
            this.socketEmit('getPartCategories', null, (results) => {
                results =  results.sort((a, b) => {
                    return a.name > b.name;
                });
                this.categories = results;
            });
        },
        async importPartData () {
            let result = await this.sendCommand({
                action: 'importPartData'
            });
            if (result.err) {
                console.error(result.err);
                this.message = result.err;
            } else {
                this.message = result.message;
            }
            this.getData();
        }
    },
    computed: {
        showAlert: {
            get () {
                return this.message !== '';
            },
            set (val) {
                if (val === false) {
                    this.message = '';
                }
            }
        },
    },
    watch: {
    },
    mounted () {
        this.getData();
        window.partsvm = this;
    }
};
</script>

<style lang="scss" scoped>


</style>

<style lang="scss">

</style>
